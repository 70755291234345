import React, { useEffect, useState } from 'react';

const BooleanQueryComponent = ({ handleInputChangeIncludedCustom }) => {
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [error, setError] = useState('');

  const booleanOperators = ['AND', 'OR', '(', ')'];

  const handleInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    if (value.length == 0) {
      setSuggestions([]);
    } else {
      updateSuggestions(value);
    }
  };

  const updateSuggestions = (value) => {
    const lastWord = value.split(' ').pop();
    const newSuggestions = booleanOperators.filter((operator) =>
      operator.startsWith(lastWord.toUpperCase()),
    );
    setSuggestions(newSuggestions);
  };

  const handleSuggestionClick = (suggestion) => {
    const words = query.split(' ');
    words.pop();
    words.push(suggestion);
    setQuery(words.join(' ') + ' ');
    setSuggestions([]);
  };

  useEffect(() => {
    handleInputChangeIncludedCustom(query);
  }, [query]);

  // const validateQuery = () => {
  //   const regex = /^[a-zA-Z\s()ANDOR]*$/;
  //   if (!regex.test(query)) {
  //     setError('Query should not contain digits or special characters.');
  //   } else {
  //     setError('');
  //   }
  // };

  return (
    <div className="relative w-full mx-auto mt-1">
      <div className="flex space-x-4">
        <textarea
          value={query}
          onChange={handleInputChange}
          // onBlur={validateQuery}
          placeholder="Included keywords"
          className="w-[70vw] h-20 p-2 border border-gray-300 rounded-md"
        />
        <textarea
          placeholder="Eg :(( Nestle AND Kitkat ) OR ( chocolate AND cookie ) OR ( coffee ))"
          class="w-[30vw] h-20 p-2 border-none focus:border-none focus:ring-0  focus:outline-none  rounded-md"
          readonly=""
        ></textarea>
      </div>
      {/* <p>Included : {query} </p> */}
      <div className="flex gap-2 mt-2">
        {/* <button
          type="button"
          // onClick={handleFetchInsights}
          className={`py-2 px-3  flex justify-flex-start items-center bg-onsecondaryvariant text-onsecondary transition ease-in duration-200 
        text-center text-base rounded hover:bg-secondary hover:text-onsecondary hover:shadow-md`}
        >
          <span className="material-icons mx-2 mt-2"></span>
          Save
        </button> */}

        {error && <p className="text-red-500 mt-2">{error}</p>}
      </div>

      {suggestions?.length > 0 && (
        <ul className="absolute w-[250px] bg-gray-100 left-[10px] top-[30px] border border-gray-300  max-h-40 overflow-y-auto shadow-lg z-10 rounded-md mt-1">
          {' '}
          {suggestions?.map((suggestion, index) => (
            <li
              key={index}
              onClick={() => handleSuggestionClick(suggestion)}
              className="p-2 cursor-pointer hover:bg-gray-200"
            >
              {suggestion}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default BooleanQueryComponent;
