import {
  DAYS,
  FACEBOOK_TYPE,
  INSTAGRAM_TYPE,
  SOCIAL_MEDIA_TYPE,
  TIKTOK_TYPE,
} from '../../../../common/constants';
import {
  INSTAGRAM_OPTIONS,
  TIKTOK_OPTIONS,
  TWITTER_OPTIONS,
  YOUTUBE_OPTIONS,
} from '../../../../common/metadata';

export const queryDetailsToSearchObject = (queryDetails) => {
  console.log(queryDetails, 'queryDetails');
  const searchObject = {
    ...queryDetails.channels,
    ...queryDetails.formData,
    articles: queryDetails?.channels?.news,
    mainSearchTerm: queryDetails?.formData?.mainSearchTerm?.toUpperCase(),
  };
  delete searchObject.news;
  return searchObject;
};

export const getSearchObject = ({
  socialMediaChannels,
  searchCriteria,
  searchBoxValue,
  twitterOption,
  tiktokOption,
  youtubeOption,
  dateselectiontype,
  days,
  startdate,
  wordsToMatch = undefined,
  wordsNotToMatch = undefined,
  enddate,
  language,
  instagramOption,
  socialMediaSearchValues,
  selectedOption,
  instagramType,
  tiktokType,
  youtubeType,
}) => {
  // ;
  const searchObject = {},
    channelQueries = {},
    formData = {};
  let atleastOneMediaSelected = false;

  for (const media of socialMediaChannels) {
    if (media.state) {
      // TODO:: remove it as it is a temporary fix
      if (
        (searchCriteria['linkedin_publicpage'] &&
          searchCriteria['linkedin_publicpage'].toLocaleLowerCase() &&
          media.label.toLowerCase() === 'linkedin') ||
        (media.label.toLowerCase() === 'linkedin' &&
          socialMediaSearchValues[media.label.toLowerCase()])
      ) {
        atleastOneMediaSelected = true;
        channelQueries['linkedin_publicpage_url'] =
          searchCriteria['linkedin_publicpage_url'] || '';
        channelQueries['linkedin_publicpage'] =
          (searchCriteria['linkedin_publicpage'] &&
            searchCriteria['linkedin_publicpage'].toLocaleLowerCase()) ||
          socialMediaSearchValues[media.label.toLowerCase()];
      }
      if (
        (media.label.toLowerCase() === 'reddit' && media.state) ||
        (media.label.toLowerCase() === 'news' && media.state) ||
        (media.label.toLowerCase() === 'instagram' && media.state) ||
        (media.label.toLowerCase() === 'tiktok' && media.state) ||
        (media.label.toLowerCase() === 'twitter' && media.state) ||
        (media.label.toLowerCase() === 'youtube' && media.state) ||
        (media.label.toLowerCase() === 'trustPilotcompanyName' && media.state)
      ) {
        atleastOneMediaSelected = true;
        channelQueries[media.label.toLowerCase()] =
          (searchCriteria[media.label.toLowerCase()] &&
            searchCriteria[media.label.toLowerCase()].toLocaleLowerCase()) ||
          (socialMediaSearchValues[media.label.toLowerCase()] &&
            socialMediaSearchValues[
              media.label.toLowerCase()
            ].toLocaleLowerCase()) ||
          searchBoxValue.toLocaleLowerCase();
      } else if (
        media.label.toLowerCase() === 'facebook' &&
        searchCriteria['facebookDisplayName'] &&
        media.state
      ) {
        atleastOneMediaSelected = true;
        channelQueries['facebookDisplayName'] =
          searchCriteria['facebookDisplayName'].toLocaleLowerCase();
        channelQueries[media.label.toLowerCase()] =
          (searchCriteria[media.label.toLowerCase()] &&
            searchCriteria[media.label.toLowerCase()].toLocaleLowerCase()) ||
          (socialMediaSearchValues[media.label.toLowerCase()] &&
            socialMediaSearchValues[
              media.label.toLowerCase()
            ].toLocaleLowerCase()) ||
          searchBoxValue.toLocaleLowerCase();
      }
    }
  }

  if (!atleastOneMediaSelected) {
    // console.log("No Media selected");
    // TODO Alert the user
    return;
  }
  if (dateselectiontype === DAYS) {
    formData.days = days;
    formData.type = 'days';
  } else {
    formData.startDate = startdate;
    formData.endDate = enddate;
    formData.type = 'date';
  }
  (formData.wordsToMatch = wordsToMatch),
    (formData.wordsNotToMatch = wordsNotToMatch),
    (formData.interval = selectedOption || 'day');
  formData.mainSearchTerm = searchBoxValue;
  formData['lang'] = language;
  formData['youtubeOption'] = youtubeOption
    ? YOUTUBE_OPTIONS[1]
    : YOUTUBE_OPTIONS[0];
  formData['twitterOption'] = twitterOption
    ? TWITTER_OPTIONS[1]
    : TWITTER_OPTIONS[0];
  formData['tiktokOption'] = tiktokOption
    ? TIKTOK_OPTIONS[1]
    : TIKTOK_OPTIONS[0];
  formData['instagramOption'] = instagramOption
    ? INSTAGRAM_OPTIONS[1]
    : INSTAGRAM_OPTIONS[0];

  formData.instagramType = instagramType || [];
  formData.tiktokType = tiktokType || [];
  formData.youtubeType = youtubeType || [];
  searchObject.channels = channelQueries;
  searchObject.formData = formData;

  return searchObject;
};

export const getSearchObjectNew = ({
  socialMediaChannels,
  searchCriteria,
  searchBoxValue,
  twitterOption,
  tiktokOption,
  youtubeOption,
  dateselectiontype,
  days,
  startdate,
  wordsToMatch = undefined,
  wordsNotToMatch = undefined,
  enddate,
  language,
  instagramOption,
  socialMediaSearchValues,
  selectedOption,
  instagramType,
  tiktokType,
  youtubeType,
}) => {
  // ;
  const searchObject = {},
    channelQueries = {},
    formData = {};
  let atleastOneMediaSelected = false;

  for (const media of socialMediaChannels) {
    if (media.state) {
      // TODO:: remove it as it is a temporary fix
      if (
        (searchCriteria['linkedin_publicpage'] &&
          searchCriteria['linkedin_publicpage'].toLocaleLowerCase() &&
          media.label.toLowerCase() === 'linkedin') ||
        (media.label.toLowerCase() === 'linkedin' &&
          socialMediaSearchValues[media.label.toLowerCase()])
      ) {
        atleastOneMediaSelected = true;
        channelQueries['linkedin_publicpage_url'] =
          searchCriteria['linkedin_publicpage_url'] || '';
        channelQueries['linkedin_publicpage'] =
          (searchCriteria['linkedin_publicpage'] &&
            searchCriteria['linkedin_publicpage'].toLocaleLowerCase()) ||
          socialMediaSearchValues[media.label.toLowerCase()];
      }
      if (
        (media.label.toLowerCase() === 'reddit' && media.state) ||
        (media.label.toLowerCase() === 'news' && media.state) ||
        (media.label.toLowerCase() === 'tiktok' && media.state) ||
        (media.label.toLowerCase() === 'twitter' && media.state) ||
        (media.label.toLowerCase() === 'youtube' && media.state) ||
        (media.label.toLowerCase() === 'trustPilotcompanyName' && media.state)
      ) {
        atleastOneMediaSelected = true;
        channelQueries[media.label.toLowerCase()] =
          (searchCriteria[media.label.toLowerCase()] &&
            searchCriteria[media.label.toLowerCase()].toLocaleLowerCase()) ||
          (socialMediaSearchValues[media.label.toLowerCase()] &&
            socialMediaSearchValues[
              media.label.toLowerCase()
            ].toLocaleLowerCase()) ||
          searchBoxValue.toLocaleLowerCase();
      } else if (
        media.label.toLowerCase() === 'facebook' &&
        searchCriteria['facebookDisplayName'] &&
        media.state
      ) {
        atleastOneMediaSelected = true;
        channelQueries['facebookDisplayName'] =
          searchCriteria['facebookDisplayName'].toLocaleLowerCase();
        channelQueries[media.label.toLowerCase()] =
          (searchCriteria[media.label.toLowerCase()] &&
            searchCriteria[media.label.toLowerCase()].toLocaleLowerCase()) ||
          (socialMediaSearchValues[media.label.toLowerCase()] &&
            socialMediaSearchValues[
              media.label.toLowerCase()
            ].toLocaleLowerCase()) ||
          searchBoxValue.toLocaleLowerCase();
      } else if (media.label.toLowerCase() === 'instagram') {
        channelQueries['instagram'] =
          instagramType.userAccount ||
          instagramType.userTag ||
          instagramType?.hashtag;
      } else if (media.label.toLowerCase() === 'tiktok') {
        channelQueries['tiktok'] =
          tiktok.userAccount || tiktok.keyword || tiktok?.hashtag;
      }
    }
  }

  if (!atleastOneMediaSelected) {
    // console.log("No Media selected");
    // TODO Alert the user
    return;
  }
  if (dateselectiontype === DAYS) {
    formData.days = days;
    formData.type = 'days';
  } else {
    formData.startDate = startdate;
    formData.endDate = enddate;
    formData.type = 'date';
  }
  (formData.wordsToMatch = wordsToMatch),
    (formData.wordsNotToMatch = wordsNotToMatch),
    (formData.interval = selectedOption || 'day');
  formData.mainSearchTerm = searchBoxValue;
  formData['lang'] = language;
  formData['youtubeOption'] = youtubeOption
    ? YOUTUBE_OPTIONS[1]
    : YOUTUBE_OPTIONS[0];
  formData['twitterOption'] = twitterOption
    ? TWITTER_OPTIONS[1]
    : TWITTER_OPTIONS[0];
  formData['tiktokOption'] = tiktokOption
    ? TIKTOK_OPTIONS[1]
    : TIKTOK_OPTIONS[0];
  formData['instagramOption'] = instagramOption
    ? INSTAGRAM_OPTIONS[1]
    : INSTAGRAM_OPTIONS[0];

  formData.instagramType = instagramType || [];
  formData.tiktokType = tiktokType || [];
  formData.youtubeType = youtubeType || [];
  searchObject.channels = channelQueries;
  searchObject.formData = formData;

  return searchObject;
};

/**
 *
 * @param {*} param0
 * @returns
 *
 * updatedPills: this is a array of updated pills. that means when user clicks on pill after that updated pills value is stared and passes in this parameter.
 * pillName: pill name has a name of pills.
 * socialMediaType: this parameter has the name of social media.
 *
 * -MrDark [Sarfaraj Shah]
 *
 *
 */
export function KeepingPillsAlwaysActive({
  updatedPills = [],
  pillName,
  socialMediaType,
  companyName,
  facebookSubTab,
  tiktokSubTab,
  instagramSubTab,
}) {
  // keepPillsActive array has active pills name only.
  let keepPillsActive = [];

  // this object has objects named with social media name as key and `keepPillsActive` array as value.
  // For Example: {twitter: [pill name 1, pill name 2]}
  let objectForPills = {};

  // here i'm getting the pills data from session storage.
  const getStorageData = sessionStorage.getItem('haiaitabs');

  /**
   * Here if `pillName` has value that means this function is bieng user to make pills active.
   * if `pillName` is null or empty or undefiend that means this is bieng used for setting pills as active
   *
   * inside if condtion it checks and makes pills active.
   * In else block it sets value of active pill in storage.
   */
  if (!!pillName) {
    // getting data from storage and converting it into json{in object}
    // browser storage stores data in string formate. so, we have to convert it into object to access the data inside object.
    const convertStringToJson = JSON.parse(getStorageData);
    // checks if storage has data or not
    if (!!convertStringToJson) {
      // checking if object from session storage has this social media or not.
      if (!!convertStringToJson[socialMediaType]) {
        // now here it is checking that session storage has this pill name or not.
        // if storage has this pill name then make the pill active.
        // if returning `true` then make pill active else don't make pills active
        if (convertStringToJson[socialMediaType][companyName]) {
          if (socialMediaType === SOCIAL_MEDIA_TYPE.instagram) {
            if (
              convertStringToJson[socialMediaType][companyName][
                INSTAGRAM_TYPE[instagramSubTab]
              ]?.includes(pillName)
            ) {
              return true;
            } else {
              return false;
            }
          } else if (socialMediaType === SOCIAL_MEDIA_TYPE.facebook) {
            if (
              convertStringToJson[socialMediaType][companyName][
                FACEBOOK_TYPE[facebookSubTab]
              ]?.includes(pillName)
            ) {
              return true;
            } else {
              return false;
            }
          } else if (socialMediaType === SOCIAL_MEDIA_TYPE.tiktok) {
            if (
              convertStringToJson[socialMediaType][companyName][
                TIKTOK_TYPE[tiktokSubTab]
              ]?.includes(pillName)
            ) {
              return true;
            } else {
              return false;
            }
          } else {
            if (
              convertStringToJson[socialMediaType][companyName]?.includes(
                pillName,
              )
            ) {
              return true;
            } else {
              return false;
            }
          }
        } else {
          return false;
        }
      }
    }
  } else {
    /**
     * Now here in else block. this coode is used to set pills value in session storage.
     * `updatedPills` has a pills data. which is in array formate.
     */
    updatedPills?.forEach(({ value, isActive }) => {
      // iterating over array and check if this pill is active then set it in storage.
      //  so, this will only store pill name which is active.
      // pills not active will not be stored in storage.
      if (isActive) {
        keepPillsActive.push(value);
      }
    });

    // here it is checcking if storage already has some data or is empty.
    if (!!getStorageData) {
      //  if storage has data tthen convert it into json [we are storing a object in session storage in string formate that's why we need to convert it into object from dtring to access object key/value]
      const convertStringToJson = JSON.parse(getStorageData);

      if (!!convertStringToJson[socialMediaType]) {
        if (socialMediaType === SOCIAL_MEDIA_TYPE.instagram) {
          if (!!convertStringToJson[socialMediaType][companyName]) {
            convertStringToJson[socialMediaType][companyName][
              INSTAGRAM_TYPE[instagramSubTab]
            ] = keepPillsActive;
          } else {
            convertStringToJson[socialMediaType] = {
              ...convertStringToJson[socialMediaType],
              [companyName]: {
                [INSTAGRAM_TYPE[instagramSubTab]]: keepPillsActive,
              },
            };
          }
        } else if (socialMediaType === SOCIAL_MEDIA_TYPE.facebook) {
          if (!!convertStringToJson[socialMediaType][companyName]) {
            convertStringToJson[socialMediaType][companyName][
              FACEBOOK_TYPE[facebookSubTab]
            ] = keepPillsActive;
          } else {
            convertStringToJson[socialMediaType] = {
              ...convertStringToJson[socialMediaType],
              [companyName]: {
                [FACEBOOK_TYPE[facebookSubTab]]: keepPillsActive,
              },
            };
          }
        } else if (socialMediaType === SOCIAL_MEDIA_TYPE.tiktok) {
          if (!!convertStringToJson[socialMediaType][companyName]) {
            convertStringToJson[socialMediaType][companyName][
              TIKTOK_TYPE[tiktokSubTab]
            ] = keepPillsActive;
          } else {
            convertStringToJson[socialMediaType] = {
              ...convertStringToJson[socialMediaType],
              [companyName]: {
                [TIKTOK_TYPE[tiktokSubTab]]: keepPillsActive,
              },
            };
          }
        } else {
          if (!!convertStringToJson[socialMediaType][companyName]) {
            convertStringToJson[socialMediaType][companyName] = keepPillsActive;
          } else {
            convertStringToJson[socialMediaType] = {
              ...convertStringToJson[socialMediaType],
              [companyName]: keepPillsActive,
            };
          }
        }
      } else {
        /**
         * if social meedia does not exists in session storage
         * set social media and assign it a object
         * this object contains company name as object key and pill name array as it's value
         *
         */
        if (socialMediaType === SOCIAL_MEDIA_TYPE.instagram) {
          convertStringToJson[socialMediaType] = {
            [companyName]: {
              [INSTAGRAM_TYPE[instagramSubTab]]: keepPillsActive,
            },
          }; // adding active pills array to object.
        } else if (socialMediaType === SOCIAL_MEDIA_TYPE.facebook) {
          convertStringToJson[socialMediaType] = {
            [companyName]: { [FACEBOOK_TYPE[facebookSubTab]]: keepPillsActive },
          }; // adding active pills array to object.
        } else if (socialMediaType === SOCIAL_MEDIA_TYPE.tiktok) {
          convertStringToJson[socialMediaType] = {
            [companyName]: { [TIKTOK_TYPE[tiktokSubTab]]: keepPillsActive },
          }; // adding active pills array to object.
        } else {
          convertStringToJson[socialMediaType] = {
            [companyName]: keepPillsActive,
          }; // adding active pills array to object.
        }
      }
      // adding object to session storage.
      sessionStorage.setItem('haiaitabs', JSON.stringify(convertStringToJson));
    } else {
      /**
       * Here if storage is empty then formate into object connvert into string and then store in storage.
       */
      if (socialMediaType === SOCIAL_MEDIA_TYPE.instagram) {
        objectForPills = {
          [socialMediaType]: {
            [companyName]: {
              [INSTAGRAM_TYPE[instagramSubTab]]: keepPillsActive,
            },
          },
        };
      } else if (socialMediaType === SOCIAL_MEDIA_TYPE.facebook) {
        objectForPills = {
          [socialMediaType]: {
            [companyName]: { [FACEBOOK_TYPE[facebookSubTab]]: keepPillsActive },
          },
        };
      } else if (socialMediaType === SOCIAL_MEDIA_TYPE.tiktok) {
        objectForPills = {
          [socialMediaType]: {
            [companyName]: { [TIKTOK_TYPE[tiktokSubTab]]: keepPillsActive },
          },
        };
      } else {
        objectForPills = {
          [socialMediaType]: {
            [companyName]: keepPillsActive,
          },
        };
      }

      sessionStorage.setItem('haiaitabs', JSON.stringify(objectForPills));
    }
  }
}
